<template>
  <div class="flex pb-24 mt-24 min-h-screen">
    <div class="px-6 mx-auto w-full max-w-md">
      <h1 :class="{ 'text-center': loggedIn }">{{ pageTitle }}</h1>
      <template v-if="!loggedIn">
        <form v-show="showing === 'login'" @submit.prevent="login">
          <div class="form-field">
            <label for="email">Email</label>
            <input name="email" type="email" v-model="userForm.email" required :disabled="isAuthenticatePending">
          </div>
          <div class="form-field">
            <label for="password">Password</label>
            <input name="password" type="password" v-model="userForm.password" required :disabled="isAuthenticatePending">
          </div>
          <button class="block py-3 px-0 w-full leading-5 btn" type="submit" :disabled="isAuthenticatePending">
            <div v-if="isAuthenticatePending" class="relative h-5">
              <loading color="white" size="small"></loading>
            </div>
            <span v-else>Login</span>
          </button>
        </form>
        <form v-show="showing === 'reset'" @submit.prevent="resetNewPassword">
          <div class="form-field">
            <label for="password">Password</label>
            <input name="password" type="password" v-model="userForm.newPassword" required :disabled="isAuthenticatePending">
          </div>
          <button class="block py-3 px-0 w-full leading-5 btn" type="submit">
            <div v-if="isAuthenticatePending" class="relative h-5">
              <loading color="white" size="small"></loading>
            </div>
            <span v-else>Reset</span>
          </button>
        </form>
        <form v-show="showing === 'forgot'" @submit.prevent="handleForgotPassword">
          <div class="form-field">
            <label for="email">Email</label>
            <input name="email" type="email" v-model="userForm.email" required :disabled="isAuthenticatePending">
          </div>
          <button class="block py-3 px-0 w-full leading-5 btn" type="submit">
            <div v-if="isAuthenticatePending" class="relative h-5">
              <loading color="white" size="small"></loading>
            </div>
            <span v-else>Get Reset Link</span>
          </button>
        </form>
        <p class="mt-8 text-center">
          <a class="underline" @click.prevent="$router.push('/forgot-password')">Forgot Password</a>
        </p>
        <p class="mt-4 text-center">
          <a class="underline" @click.prevent="$router.push('/register')">Or register here</a>
        </p>
        <div class="mt-8 h-32">
          <p v-if="info" class="py-2 px-3 text-green-600 bg-green-300 bg-opacity-20 rounded">{{ info }}</p>
          <p v-if="error" class="py-2 px-3 text-red-700 bg-red-300 bg-opacity-20 rounded">{{ error }}</p>
        </div>
      </template>
      <template v-else>
        <p class="-mt-4 text-center text-gray-400">Logging you in ...</p>
        <div class="relative my-24">
          <loading/>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { authManagement } from '../../feathers-client'
import Loading from '@/components/Loading'

export default {
  data() {
    return {
      pageTitle: 'Welcome back!',
      showing: 'login',
      userForm: {
        email: '',
        password: '',
        newPassword: ''
      },
      error: '',
      info: '',
      isLoading: false
    }
  },
  components: { Loading },
  computed: {
    ...mapState('auth', ['isAuthenticatePending']),
    loggedIn() {
      return localStorage.getItem('feathers-jwt') && !this.$route.query.redirect
    }
  },
  methods: {
    login() {
      this.info = ''
      this.error = ''
      this.$store.commit('auth/setAuthenticatePending')
      if (this.userForm.email && this.userForm.password) {
        if (this.$store.state.errorOnAuthenticate) {
          this.$store.commit('auth/clearAuthenticateError')
        }
        authManagement.authenticate(this.userForm.email, this.userForm.password)
          .then(result => {
            if (result.type === 'FeathersError') {
              this.$store.commit('auth/setAuthenticateError', result)
              this.$store.commit('auth/unsetAuthenticatePending')
              this.error = result.message
            } else {
              this.$store.dispatch('auth/responseHandler', result)
              if (this.$route.query.redirect) {
                this.$router.push(this.$route.query.redirect)
              } else {
                this.$router.push('/app/desk')
              }
            }
          })
      }
    },
    showForgotPassword() {
      this.showing = 'forgot'
      this.pageTitle = 'Forgot Password'
    },
    handleForgotPassword() {
      this.info = ''
      this.error = ''
      if (this.userForm.email) {
        authManagement.sendResetPwd({ email: this.userForm.email })
          .then(() => {
            this.info = 'Password reset link has been sent to your email.'
          })
          .catch((error) => {
            this.error = error.message
          })
      } else {
        this.error = 'Email is required'
      }
    },
    resetNewPassword() {
      this.info = ''
      this.error = ''
      authManagement.resetPwdLong(this.$route.query.token, this.userForm.newPassword)
        .then(() => {
          this.info = 'Your password has been reset'
          this.userForm.newPassword = ''
        })
        .catch((error) => {
          this.error = error.message
        })
      this.showing = 'login'
      this.pageTitle = 'Welcome Back!'
    },
    checkVerifyOrReset() {
      if (this.$route.params.type === 'verify') {
        authManagement.verifySignupLong(this.$route.query.token)
          .then(result => {
            this.info = 'Your email has been verified.'
            if (result) {
              this.userForm.email = result.email
            }
          })
          .catch((error) => {
            this.error = error.message
          })
      } else if (this.$route.params.type === 'reset') {
        this.pageTitle = 'Reset Password'
        this.showing = 'reset'
      }
    }
  },
  mounted() {
    if (this.loggedIn) {
      setTimeout(() => {
        this.$router.push('/app/desk')
      }, 700)
    }
    if (this.$route.params.type) {
      this.isLoading = true
      this.checkVerifyOrReset()
    }
  }
}
</script>
